/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Hospodaření školy "}>
        <Column className="pb--80 pt--80" name={"hhs38dzsrti"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: rgb(26, 46, 213);\">Návrh rozpočtu na rok 2024</span>"}>
              </Title>

              <Button className="btn-box" use={"file"} href={"https://cdn.swbpg.com/d/13139/navrh-rozpoctu-2024.pdf"} style={{"backgroundColor":"rgba(65,195,248,1)"}} content={"<span style=\"color: rgb(24, 23, 23);\">Kliknutím zde zobrazíte</span><br>"} document={"6dd05ea1c5aa49459da12e212f3b8861"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"wviwch10rt"} style={{"backgroundColor":"#50daec"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: rgb(12, 28, 149);\">Rozpočet na rok 2023</span>"}>
              </Title>

              <Button className="btn-box" use={"file"} href={"https://cdn.swbpg.com/d/13139/2023-3-rozpocet-upraveny-2023.pdf"} style={{"backgroundColor":"rgba(246,246,248,1)"}} content={"<span style=\"color: rgb(93, 125, 219);\">Kliknutím zde zobrazíte</span><br>"} document={"2a331b937b1a40ae9431a657c41250aa"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pt--40" name={"9xj2v8ye5u"} style={{"paddingBottom":0,"backgroundColor":"#2047e9"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--30 pt--25" name={"s17kuadx9ah"} style={{"backgroundColor":"#c0e6f9"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"backgroundColor":"rgba(192,230,249,1)"}}>
              
              <Title className="title-box title-box--center fs--43" content={"<span style=\"color: rgb(21, 74, 195);\">Účetní závěrka Mateřské školy Litultovice 2022</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"gyerafza1xb"} style={{"backgroundColor":"#aae0f0"}}>
          
          <ColumnWrap className="column__flex --center el--3 flex--bottom" columns={"3"}>
            
            <ColumnWrapper >
              
              <Text className="text-box fs--36" content={"<span style=\"color: rgb(55, 112, 232);\">Rozvaha</span>"}>
              </Text>

              <Button className="btn-box" use={"file"} href={"https://cdn.swbpg.com/d/13139/2022-rozvaha-ms-litultovice-rok.pdf"} style={{"backgroundColor":"rgba(242,241,246,1)"}} content={"<span style=\"color: rgb(79, 119, 237);\">Kliknutím zde zobrazíte</span><br>"} document={"3393f2acd4044826b07dd702f0cd2a16"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box fs--36" content={"<span style=\"color: rgb(52, 106, 230);\">Výkaz zisku a ztráty</span>"}>
              </Text>

              <Button className="btn-box" use={"file"} href={"https://cdn.swbpg.com/d/13139/20222-vykaz-zisku-a-ztraty-ms-litultovice.pdf"} style={{"backgroundColor":"rgba(247,246,253,1)"}} content={"<span style=\"color: rgb(88, 123, 229);\">Kliknutím zde&nbsp;zobrazíte</span>"} document={"f4bc79d2857e4f808a7416151a66e96b"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--36" content={"<span style=\"color: rgb(57, 77, 232);\">Příloha</span>"}>
              </Title>

              <Button className="btn-box" use={"file"} href={"https://cdn.swbpg.com/d/13139/2022-priloha-ms-litultovice.pdf"} style={{"backgroundColor":"rgba(247,247,248,1)"}} content={"<span style=\"color: rgb(63, 99, 210);\">Kliknutím zde zobrazíte</span><br>"} document={"e9e7c96d9933455681f43b882134c296"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40" name={"eocbd1w7dyt"} style={{"paddingTop":0,"backgroundColor":"#2047e9"}}>
        </Column>


        <Column className="pb--20 pt--20" name={"yn75bviadv"} style={{"backgroundColor":"#c0e6f9"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: rgb(69, 87, 237);\">Rozpočet na rok 2023<br><br></span>"}>
              </Title>

              <Button className="btn-box" use={"file"} href={"https://cdn.swbpg.com/d/13139/rozpocet-ms-2-2023.pdf"} style={{"maxWidth":984,"backgroundColor":"rgba(250,250,250,1)"}} content={"<span style=\"color: rgb(81, 81, 240);\">Kliknutím zde zobrazíte</span>"} document={"20d0fe8c7aa24d6ca262e4710376f062"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pt--40" name={"l2os4f9053m"} style={{"paddingBottom":0,"backgroundColor":"#2047e9"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"f1pq8fc8lu"} style={{"backgroundColor":"#c0e6f9"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--36" content={"<span style=\"color: rgb(70, 88, 233);\">Střednědobý výhled rozpočtu<br></span><span style=\"color: rgb(47, 47, 242);\">2023 - 2025</span><br>"}>
              </Title>

              <Button className="btn-box" use={"file"} href={"https://cdn.swbpg.com/d/13139/stredne-doby-vyhled-2023-25-schvaleny.pdf"} style={{"backgroundColor":"rgba(246,246,249,1)"}} content={"<span style=\"color: rgb(82, 82, 222);\">Kliknutím zde&nbsp;zobrazíte</span>"} document={"62daff15d9944789b353df1874f6c64b"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--10 pl--25 pr--25 pt--10" name={"prazdna_sekcee"} style={{"backgroundColor":"#2047e9"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1 --full mt--10 pb--10" columns={"1"} fullscreen={true}>
          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}